import { postJSON } from 'bv-fetch';

export const apiSubmitQuestionnaire = (questions, appliedMask, questionnaireId) => {
  const postData = {
    questions,
    type: appliedMask,
  };
  if (questionnaireId) postData.id = questionnaireId;
  return postJSON('/safer_gambling/questionnaire', postData);
};

export const apiStartInteractionWithQuestionnaire = () => postJSON('/safer_gambling/interactions');
