import { t } from 'bv-i18n';
import { apiSubmitQuestionnaire } from './api';

const CHOOSE_ANSWER = 'questionnaire/CHOOSE_ANSWER';
const SUBMIT_QUESTIONNAIRE_INIT = 'questionnaire/SUBMIT_INIT';
const SUBMIT_QUESTIONNAIRE_SUCCESS = 'questionnaire/SUBMIT_SUCCESS';
const SUBMIT_QUESTIONNAIRE_FAILURE = 'questionnaire/SUBMIT_FAILURE';
const RESET_STATE = 'questionnaire/RESET_STATE';
const SET_QUESTIONS = 'questionnaire/SET_QUESTIONS';
const SET_QUESTIONNAIRE_ID = 'questionnaire/SET_QUESTIONNAIRE_ID';

export const setQuestions = (questions) => ({
  type: SET_QUESTIONS,
  questions,
});

export const setQuestionnaireId = (id) => ({
  type: SET_QUESTIONNAIRE_ID,
  id,
});

export const chooseAnswer = ({ questionId, answer }) => ({
  type: CHOOSE_ANSWER,
  questionId,
  answer,
});

export const submitQuestionnaireInit = () => ({
  type: SUBMIT_QUESTIONNAIRE_INIT,
});

export const submitQuestionnaireSuccess = () => ({
  type: SUBMIT_QUESTIONNAIRE_SUCCESS,
});

export const submitQuestionnaireFailure = (redirect) => ({
  type: SUBMIT_QUESTIONNAIRE_FAILURE,
  redirect,
});

export const resetState = () => ({
  type: RESET_STATE,
});

export const submitQuestionnaire = (stateNamespace, appliedMask) => (dispatch, getState) => {
  dispatch(submitQuestionnaireInit());

  const adjustedQuestions = getState()[stateNamespace].questions.map((question) => ({
    order: question.id,
    externalId: question.id,
    title: t(question.title),
    answer: question.answer,
  }));

  const { questionnaireId } = getState()[stateNamespace];

  return apiSubmitQuestionnaire(adjustedQuestions, appliedMask, questionnaireId)
    .then((response) => {
      if (response.questionnaireSubmissionResult === 'PASS') {
        dispatch(submitQuestionnaireSuccess());
      } else {
        dispatch(submitQuestionnaireFailure(response.redirect));
      }
    });
};

const initialState = {
  questions: [],
  questionnaireIsSubmitting: false,
  questionnaireSubmitFailed: false,
  questionnaireSubmitSucceeded: false,
  questionnaireId: null,
  failureRedirection: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case CHOOSE_ANSWER:
      return {
        ...state,
        questions: state.questions.map((question) => {
          if (question.id === action.questionId) {
            return { ...question, answer: action.answer };
          }
          return question;
        }),
      };
    case SUBMIT_QUESTIONNAIRE_INIT:
      return {
        ...state,
        questionnaireIsSubmitting: true,
      };
    case SUBMIT_QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        questionnaireIsSubmitting: false,
        questionnaireSubmitSucceeded: true,
      };
    case SUBMIT_QUESTIONNAIRE_FAILURE:
      return {
        ...state,
        questionnaireIsSubmitting: false,
        questionnaireSubmitFailed: true,
        failureRedirection: action.redirect,
      };
    case RESET_STATE:
      return initialState;
    case SET_QUESTIONS:
      return {
        ...state,
        questions: action.questions,
      };
    case SET_QUESTIONNAIRE_ID:
      return {
        ...state,
        questionnaireId: action.id,
      };
    default:
      return state;
  }
};
