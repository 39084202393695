import { connect } from 'react-redux';
import { anyQuestionsWithoutAnswers } from '../selectors';
import {
  chooseAnswer,
  submitQuestionnaire,
  resetState,
} from '../duck';
import App from './app';
import { REDIRECTION_TARGET } from '../constants';

const mapStateToProps = (state, ownProps) => {
  const {
    [ownProps.stateNamespace]: {
      questions,
      questionnaireIsSubmitting,
      questionnaireSubmitSucceeded,
      questionnaireSubmitFailed,
      failureRedirection,
    },
  } = state;

  return {
    anyQuestionsWithoutAnswers: anyQuestionsWithoutAnswers(state, ownProps),
    questions,
    questionnaireIsSubmitting,
    questionnaireSubmitSucceeded,
    questionnaireSubmitFailed,
    failureRedirection: REDIRECTION_TARGET[failureRedirection],
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  chooseAnswer: ({ questionId }) => ({ answer }) => dispatch(chooseAnswer({ questionId, answer })),
  submitQuestionnaire: () => {
    const { stateNamespace, appliedMask } = ownProps;

    dispatch(submitQuestionnaire(stateNamespace, appliedMask));
  },
  resetState: () => dispatch(resetState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
