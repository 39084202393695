import PropTypes from 'prop-types';
import { Modal } from 'bv-components';
import { t } from 'bv-i18n';

const SuccessSubmitModal = ({
  onClose, buttonLabelKey, translationsNamespace,
}) => (
  <Modal
    success
    actions={[{
      id: 'ok-btn',
      label: t(buttonLabelKey),
      primary: true,
      close: true,
      onClick: onClose,
      inverse: true,
      className: 'questionnaire-modal__redirect-button',
    }]}
  >
    <p className="questionnaire__success-txt bvs-text-success">
      {t(`${translationsNamespace}.cta.result_ok_message`)}
    </p>
  </Modal>
);

SuccessSubmitModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  buttonLabelKey: PropTypes.string.isRequired,
  translationsNamespace: PropTypes.string.isRequired,
};

export default SuccessSubmitModal;
