import { Component } from 'react';
import PropTypes from 'prop-types';
import { Heading, Spinner } from 'bv-components';
import { t } from 'bv-i18n';
import { setLocation } from 'bv-helpers/location';
import Question from './question';
import SubmitButton from './submit_button';
import FailureSubmitModal from './failure_submit_modal';
import SuccessSubmitModal from './success_submit_modal';
import { REDIRECTION_TARGET } from '../constants';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = { canRenderSubmitButton: false };

    if (props.onMountCallback) {
      this.state = { ...this.state, onMountCallbackResolved: false };
    }
  }

  componentDidMount() {
    /* eslint react/no-did-mount-set-state:off */

    // TODO: This is temp fix for bug with missing sticky button that is rendered outside of modal.
    // Because solution for sticky buttons is based on portals, they need a div to render to,
    // if it’s not created before it won’t work.
    // This div is not created here if component is rendered immediately,
    // without any assets loading or spinner that is displayed on first component render.

    this.setState({ canRenderSubmitButton: true });

    const { onMountCallback } = this.props;

    if (onMountCallback) {
      onMountCallback().then(() => {
        this.setState({ onMountCallbackResolved: true });
      });
    }
  }

  componentWillUnmount() {
    const { resetState } = this.props;

    resetState();
  }

  render() {
    const {
      questions,
      chooseAnswer,
      anyQuestionsWithoutAnswers,
      submitQuestionnaire,
      questionnaireIsSubmitting,
      questionnaireSubmitSucceeded,
      questionnaireSubmitFailed,
      handleSuccessModalClose,
      handleFailureModalClose,
      successModalButtonLabelKey,
      translationsNamespace,
      onMountCallback,
      editable,
      failureRedirection,
    } = this.props;

    const { onMountCallbackResolved, canRenderSubmitButton } = this.state;

    const shouldDisplaySpinner = questionnaireIsSubmitting || (
      onMountCallback && !onMountCallbackResolved
    );

    const handleRedirect = () => setLocation(failureRedirection);

    return (
      shouldDisplaySpinner ? <Spinner /> : (
        <>
          <div className="questionnaire">
            <Heading tag="h2" weight="6">
              {t(`${translationsNamespace}.all_questions_are_mandatory`)}
            </Heading>
            {questions.map(({ id, title, answer }) => (
              <Question
                key={id}
                title={title}
                answer={answer}
                handleAnswerBtnClick={chooseAnswer({ questionId: id })}
                translationsNamespace={translationsNamespace}
              />
            ))}
            {canRenderSubmitButton && (
              <SubmitButton
                disabled={anyQuestionsWithoutAnswers || !editable}
                handleClick={submitQuestionnaire}
                translationsNamespace={translationsNamespace}
              />
            )}
          </div>
          {questionnaireSubmitFailed && (
            <FailureSubmitModal
              onClose={failureRedirection ? handleRedirect : handleFailureModalClose}
              translationsNamespace={translationsNamespace}
              redirectToSGP={failureRedirection === REDIRECTION_TARGET.SAFER_GAMBLING_PORTAL}
            />
          )}
          {questionnaireSubmitSucceeded && (
            <SuccessSubmitModal
              onClose={handleSuccessModalClose}
              buttonLabelKey={successModalButtonLabelKey}
              translationsNamespace={translationsNamespace}
            />
          )}
        </>
      )
    );
  }
}

App.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    answer: PropTypes.bool,
  })).isRequired,
  chooseAnswer: PropTypes.func.isRequired,
  anyQuestionsWithoutAnswers: PropTypes.bool.isRequired,
  submitQuestionnaire: PropTypes.func.isRequired,
  questionnaireIsSubmitting: PropTypes.bool.isRequired,
  questionnaireSubmitSucceeded: PropTypes.bool.isRequired,
  questionnaireSubmitFailed: PropTypes.bool.isRequired,
  resetState: PropTypes.func.isRequired,
  handleSuccessModalClose: PropTypes.func.isRequired,
  handleFailureModalClose: PropTypes.func.isRequired,
  successModalButtonLabelKey: PropTypes.string.isRequired,
  translationsNamespace: PropTypes.string.isRequired,
  onMountCallback: PropTypes.func,
  editable: PropTypes.bool,
  failureRedirection: PropTypes.string,
};

App.defaultProps = {
  onMountCallback: null,
  editable: true,
  failureRedirection: null,
};

export default App;
