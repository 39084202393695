import PropTypes from 'prop-types';
import { Button, RenderInModal } from 'bv-components';
import { t } from 'bv-i18n';

const SubmitButton = (props) => {
  const { handleClick, disabled, translationsNamespace } = props;

  return (
    <RenderInModal>
      <div className={`modal_sticky_button_wrapper bvs-button-sticky-wrapper ${disabled ? 'disabled' : null}`}>
        <Button
          type="submit"
          label={t(`${translationsNamespace}.submit`)}
          disabled={disabled}
          onClick={handleClick}
          className="bvs-button-sticky"
        />
      </div>
    </RenderInModal>
  );
};

SubmitButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  translationsNamespace: PropTypes.string.isRequired,
};

export default SubmitButton;
