import { PropTypes } from 'prop-types';
import Questionnaire from './components/app_redux';
import reducer, { setQuestions, setQuestionnaireId } from './duck';

const { addReducers, store: { dispatch } } = window.reduxState;

const App = (props) => {
  const {
    translationsNamespace, stateNamespace, questionsIdentifiersList, questionnaireId,
  } = props;

  addReducers({
    [stateNamespace]: reducer,
  });

  const questions = questionsIdentifiersList.map((questionId) => ({
    id: questionId,
    title: `${translationsNamespace}.questions.${questionId}`,
  }));
  dispatch(setQuestions(questions));
  dispatch(setQuestionnaireId(questionnaireId));

  return <Questionnaire {...props} />;
};

App.propTypes = {
  stateNamespace: PropTypes.string.isRequired,
  questionsIdentifiersList: PropTypes.instanceOf(Array).isRequired,
  translationsNamespace: PropTypes.string.isRequired,
  questionnaireId: PropTypes.number,
};

App.defaultProps = {
  questionnaireId: null,
};

export default App;
