import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button, Card } from 'bv-components';
import { t } from 'bv-i18n';

const Question = (props) => {
  const {
    title, answer, handleAnswerBtnClick, translationsNamespace,
  } = props;
  const classNameNegative = classnames('bvs-button-chip', { active: answer === false });
  const classNamePositive = classnames('bvs-button-chip', { active: answer });

  return (
    <Card className="questionnaire__question">
      <p>{t(title)}</p>
      <Button
        noClass
        className={classNameNegative}
        label={t(`${translationsNamespace}.false`)}
        onClick={() => handleAnswerBtnClick({ answer: false })}
      />
      <Button
        noClass
        className={classNamePositive}
        label={t(`${translationsNamespace}.true`)}
        onClick={() => handleAnswerBtnClick({ answer: true })}
      />
    </Card>
  );
};

Question.propTypes = {
  title: PropTypes.string.isRequired,
  answer: PropTypes.bool,
  handleAnswerBtnClick: PropTypes.func.isRequired,
  translationsNamespace: PropTypes.string.isRequired,
};

Question.defaultProps = {
  answer: null,
};

export default Question;
