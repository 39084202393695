import PropTypes from 'prop-types';
import { Modal } from 'bv-components';
import { t } from 'bv-i18n';

const FailureSubmitModal = ({ onClose, translationsNamespace, redirectToSGP }) => {
  const translationsSuffix = redirectToSGP ? '_redirect_SGP' : '';

  return (
    <Modal
      danger
      actions={[{
        id: 'ok-btn',
        label: t(`${translationsNamespace}.cta.result_fail_cta${translationsSuffix}`),
        danger: true,
        onClick: onClose,
        inverse: true,
        className: 'questionnaire-modal__redirect-button',
      }]}
    >
      <p className="questionnaire__failure-txt">
        {t(`${translationsNamespace}.cta.result_fail_message${translationsSuffix}`)}
      </p>
    </Modal>
  );
};

FailureSubmitModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  translationsNamespace: PropTypes.string.isRequired,
  redirectToSGP: PropTypes.bool,
};

FailureSubmitModal.defaultProps = {
  redirectToSGP: false,
};

export default FailureSubmitModal;
